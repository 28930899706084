<template>
  <div class="row">
    <div class="col-6">
      <div class="form-group" v-show="false">
        <label for="emailAddress" class="col-sm-2 col-form-label">Your Email Address</label>
        <div class="col-sm-4">
          <input
              id="emailAddress"
              type="email"
              placeholder="Insert Email Address"
              autocomplete="off"
              :value="$store.getters.emailAddress"
              @input="sendInput($event.target.value, 'emailAddress')"
              class="form-control"
          />
        </div>
      </div>
      <div class="form-group" v-if="usingPartnerDropdown()">
        <label for="partnerName" class="col-form-label">Partner Name</label>
        <div>
          <v-select
              id="partnerName"
              placeholder="Select Partner Name"
              @input="value => sendInput(value, 'partnerName')"
              :options="partnersList"
              :clearable="false">
          </v-select>
        </div>
      </div>
      <div class="form-group">
        <label for="clientName" class="col-form-label">Client Name</label>
        <div>
          <input
              id="clientName"
              type="text"
              placeholder="Insert Client Name"
              autocomplete="off"
              :value="$store.getters.clientName"
              @input="sendInput($event.target.value, 'clientName')"
              class="form-control"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="clientId" class="col-form-label">Client ID/Reference</label>
        <div>
          <input
              id="clientId"
              type="text"
              placeholder="Insert Client Reference"
              autocomplete="off"
              :value="$store.getters.clientRef"
              @input="sendInput($event.target.value, 'clientRef')"
              class="form-control"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="inceptionDate" class="col-form-label">Inception Date</label>
        <div>
          <date-picker
              id="inceptionDate"
              :value="inceptionDate"
              placeholder="Inception Date"
              :monday-first="true"
              format="dd MMM yyyy"
              @input="value => sendInput(value, 'inceptionDate')"
              :disabled-dates="{ to: new Date() }"
              input-class="date-picker-input"
          ></date-picker>
        </div>
      </div>
    </div>

    <div class="col-6">
      <br>
      <div class="form-group">
        <label for="selClassOfBusiness">Class of Business *</label>
        <v-select
            id="selClassOfBusiness"
            aria-describedby="spnPolicyClassError"
            :class="{ 'is-invalid': classTypeHasError }"
            label="policy_class_type_caption"
            :options="$store.getters.classTypes"
            :disabled="$store.getters.classTypes.length===0"
            :value="$store.getters.classType"
            :clearable="true"
            placeholder="Select a Class of Business"
            @input="val => changeClass(val)"
        ></v-select>
        <span
            id="spnClassTypeError"
            class="invalid-feedback"
            v-if="classTypeHasError"
        >{{ classTypeError }}
            </span>
      </div>
      <div class="form-group">
        <label for="selPolicyClass">Policy Type *</label>
        <v-select
            id="selPolicyClass"
            label="policy_class_caption"
            :class="{ 'is-invalid': policyClassHasError }"
            :options="$store.getters.policyClasses"
            :value="$store.getters.policyClass"
            :clearable="true"
            aria-describedby="spnPolicyClassError"
            placeholder="Select a Policy Type"
            :disabled="
                $store.getters.classType === null ||
                  $store.getters.policyClasses.length === 0
              "
            @input="policyClassSelected"
        ></v-select>
        <span
            id="spnPolicyClassError"
            class="invalid-feedback"
            v-if="policyClassHasError"
        >{{ policyClassError }}
            </span>
      </div>
      <div class="form-group">
        <label class="col-form-label" for="inpProgrammeName">
          Programme Name
        </label>
        <div>
          <input
              class="form-control"
              aria-describedby="spnProgrammeNameError"
              type="text"
              id="inpProgrammeName"
              autocomplete="off"
              :value="$store.getters.programmeName"
              @input="setProgrammeName"
              placeholder="Name"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeaturesMixin from "@/mixins/features.mixin";

export default {
  name: "InquirySingleDetailsPane",
  mixins: [FeaturesMixin],
  props: {
    classTypeHasError: {
      type: Boolean,
      default: false,
    },
    classTypeError: {
      type: String,
      default: ""
    },
    policyClassHasError: {
      type: Boolean,
      default: false,
    },
    policyClassError: {
      type: String,
      default: "",
    },
    countriesHasError: {
      type: Boolean,
      default: false,
    },
    countriesError: {
      type: String,
      default: "",
    },
    partnersList: {
      type: Array,
      default: () => []
    },
  },
  data: () => {
    return {
      emailAddress: "",
      clientName: "",
      clientId: "",
      inceptionDate: new Date(),
      appliedCoverDataToCountries: false,
      fieldsChangedWithoutReload: false,
      maxPolicyLimit: 10000000, // Ten million (USD)
    };
  },
  methods: {
    sendInput(value, type) {
      const payload = {};
      payload[type] = value;
      this.$store.dispatch("setPolicyDetail", payload);
    },
    clearResultsGoToCountrySelect() {
      this.fieldsChangedWithoutReload = true;
      if (this.$router.history.current.name !== "Country Select") {
        this.$router.push({ name: "Country Select" });
        document
            .getElementById("topClearSelectionsBtn")
            .scrollIntoView({ behavior: "smooth" });
      }
    },
    policyClassSelected(val) {
      this.policyClassHasError = false;
      this.policyClassError = "";
      this.clearResultsGoToCountrySelect();
      this.$store.dispatch("changePolicyClass", { policyClass: val });
    },
    setProgrammeName(event) {
      this.$store.commit("setProgrammeName", {
        name: event.target.value
      });
    },
    changeClass(val) {
      this.classTypeHasError = false;
      this.classTypeError = "";
      this.clearResultsGoToCountrySelect();
      this.$store.dispatch("changeClassOfBusiness", { classType: val });
    },
  }
}
</script>
